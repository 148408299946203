import styled, { css } from 'styled-components';
import Box from '../Box';

const Card = styled(Box)(
  ({ theme }) => css`
    padding: ${theme.space[4]}px;
    border-radius: 2px;
    box-shadow: 0 0 4px 1px rgba(128, 141, 150, 0.15);
    background-color: ${theme.colors.white};
  `,
);

export default Card;
