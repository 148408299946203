import styled, { css } from 'styled-components';
import { border, space } from 'styled-system';

import Box from '../Box';

const CardRow = styled(Box)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding-right: ${theme.space[4]}px;
    padding-left: ${theme.space[4]}px;
    margin-right: -${theme.space[4]}px;
    margin-left: -${theme.space[4]}px;
    margin-bottom: ${theme.space[4]}px;

    &:first-child,
    & + & {
      margin-top: -${theme.space[4]}px;
    }

    &:last-child {
      margin-bottom: -${theme.space[4]}px;
    }
  `,
  border,
  space,
);

export default CardRow;
