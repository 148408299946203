import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

import Box from '../Box';
import Text from '../Text';

const AlertBox = styled(Box)(
  ({ theme }) => css`
    border-radius: 4px;
    border: 1px solid #fb627a;
    text-align: center;
    padding: ${theme.space[3]}px;
  `,
  variant({
    variants: {
      danger: {
        bg: '#fef4f8',
        color: '#e80268',
      },
      warning: {
        bg: '#fcefe4',
        color: '#fc7d20',
        border: '1px solid #fc7d20',
      },
    },
  }),
);

const Alert = ({ children, ...props }) => (
  <AlertBox {...props}>
    <Text color="currentColor" fontSize="1" fontWeight="bold">
      {children}
    </Text>
  </AlertBox>
);

Alert.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Alert;
