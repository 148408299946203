import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import AppShell from 'app/AppShell';
import Loader from 'common/Loader';
import { isCurrentLocaleValid, localizePath } from 'utils/I18n';
import { getPlainPath } from 'utils/RoutingHelper';
import { Background } from 'utils/Styles';
import { getUserMode } from 'utils/UserMode';

const StudentAppLazy = React.lazy(() =>
  import(/* webpackChunkName: "student" */ 'student/StudentApp'),
);

const TeacherAppLazy = React.lazy(() =>
  import(/* webpackChunkName: "teacher" */ 'teacher/TeacherApp'),
);

const AppLazy = ({ kind }) => {
  if (kind === 'teacher') {
    return getUserMode() === 'student' ? (
      <StudentAppLazy />
    ) : (
      <TeacherAppLazy />
    );
  }

  // student will only be able to
  // login on the student app
  return <StudentAppLazy />;
};

AppLazy.propTypes = {
  kind: PropTypes.string.isRequired,
};

const App = () => {
  const currentPath = useLocation().pathname;
  const plainPath = getPlainPath(currentPath);

  const restrictedRedirect = user =>
    user.restricted &&
    ['/', '/login'].includes(plainPath) && (
      <Redirect to={localizePath('/restricted')} noThrow />
    );

  const dashboardRedirect = user =>
    user.token &&
    !user.restricted &&
    ['/', '/login'].includes(plainPath) && (
      <Redirect to={localizePath('/dashboard')} noThrow />
    );

  const localeRedirect = user =>
    user.token && (
      <Redirect from={plainPath} to={localizePath(plainPath)} noThrow />
    );

  const invalidLocaleRedirect = user =>
    user.token &&
    !isCurrentLocaleValid() && (
      <Redirect to={localizePath(plainPath)} noThrow />
    );

  const unauthorizedRedirect = user =>
    !user.token &&
    currentPath !== localizePath('/login') && (
      <Redirect to={localizePath('/login')} noThrow />
    );

  return (
    <AppShell>
      {({ user }) => (
        <Suspense fallback={<Loader />}>
          <Background color="background" />
          <Switch>
            {restrictedRedirect(user)}
            {dashboardRedirect(user)}
            {localeRedirect(user)}
            {invalidLocaleRedirect(user)}
            {unauthorizedRedirect(user)}
            <Route path={localizePath()}>
              <AppLazy kind={user.kind} />
            </Route>
          </Switch>
        </Suspense>
      )}
    </AppShell>
  );
};

export default App;
