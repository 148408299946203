import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import LocaleSwitcher from 'common/LocaleSwitcher';
import { Box, BoxWide, Text, Button } from 'q-system';
import { getUserMode, setDevelopmentUserMode } from 'utils/UserMode';
import useWindowSize from 'utils/Hooks';

const logoUrl = require('assets/images/logos/logo-quipper-footer.svg');

const DESKTOP_WIDTH = 920;

const footerLinkStyles = {
  display: 'inline-block',
  color: 'secondary',
  fontSize: 1,
  textDecoration: 'none',
  mr: 5,
  lineHeight: 2,
};

const StyledBoxWide = styled(BoxWide)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column-reverse column-reverse row;
    justify-content: space-between;
    align-items: flex-start center;

    background-color: #f6f7fa;
    border-top-color: ${theme.colors.border};
    border-style: solid;
    border-top-width: 1px;
    min-height: 96px;
  `,
);

const LogoImg = styled.img`
  width: 96px;
  height: 24px;
`;

const AppFooter = props => {
  const currentYear = new Date().getFullYear();
  const { formatMessage: f } = useIntl();
  const userMode = getUserMode();
  const size = useWindowSize();
  const isDesktop = size?.width >= DESKTOP_WIDTH;

  const toggleUserMode = () => {
    const nextUserMode = userMode === 'teacher' ? 'student' : 'teacher';
    setDevelopmentUserMode(nextUserMode);
    window.location = '/';
  };

  const mobileFooterRenderer = () => {
    return (
      <Box style={{ backgroundColor: '#0c2846' }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingTop: '20px',
            paddingLeft: '16px',
            paddingRight: '50px',
          }}
        >
          <LocaleSwitcher mobile />

          <Text
            as="a"
            href={f({ id: 'footer.terms_url' })}
            target="_blank"
            rel="noopener"
            {...footerLinkStyles}
            style={{
              color: '#ffffff',
            }}
          >
            {f({ id: 'footer.terms' })}
          </Text>
          <Text
            as="a"
            href={f({ id: 'footer.privacy_url' })}
            target="_blank"
            rel="noopener"
            {...footerLinkStyles}
            style={{
              color: '#ffffff',
            }}
          >
            {f({ id: 'footer.privacy' })}
          </Text>
          <Text
            as="a"
            href={f({ id: 'footer.contact_url' })}
            target="_blank"
            rel="noopener"
            {...footerLinkStyles}
            style={{
              color: '#ffffff',
            }}
          >
            {f({ id: 'footer.contact' })}
          </Text>
        </Box>
        <Box
          style={{
            paddingTop: 20,
            paddingBottom: 24,
            paddingLeft: 16,
            display: 'flex',
          }}
        >
          <LogoImg src={logoUrl} />
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text
              as="small"
              fontSize="0"
              ml="3"
              mt="1"
              style={{ color: '#ffffff' }}
            >
              {f({ id: 'footer.copyright' }, { year: currentYear })}
            </Text>
          </Box>
        </Box>
      </Box>
    );
  };

  const desktopFooterRenderer = () => {
    return (
      <StyledBoxWide as="footer" py="3" {...props}>
        <Box display="flex" alignItems="center" pt={['3', '3', '0']}>
          <Text as="small" color="secondary" fontSize="0" ml="5">
            {f({ id: 'footer.copyright' }, { year: currentYear })}
          </Text>
        </Box>

        <Box display="flex" alignItems="center">
          <LocaleSwitcher />
          <Text
            as="a"
            href={f({ id: 'footer.terms_url' })}
            target="_blank"
            rel="noopener"
            {...footerLinkStyles}
          >
            {f({ id: 'footer.terms' })}
          </Text>
          <Text
            as="a"
            href={f({ id: 'footer.privacy_url' })}
            target="_blank"
            rel="noopener"
            {...footerLinkStyles}
          >
            {f({ id: 'footer.privacy' })}
          </Text>
          <Text
            as="a"
            href={f({ id: 'footer.contact_url' })}
            target="_blank"
            rel="noopener"
            {...footerLinkStyles}
          >
            {f({ id: 'footer.contact' })}
          </Text>
          {process.env.NODE_ENV === 'development' && (
            <Button
              data-testid="toggle-user-mode"
              variant="text"
              color="accent"
              width="100px"
              onClick={toggleUserMode}
            >
              {userMode === 'teacher' ? 'Student app' : 'Teacher app'}
            </Button>
          )}
        </Box>
      </StyledBoxWide>
    );
  };

  return isDesktop ? desktopFooterRenderer() : mobileFooterRenderer();
};

export default AppFooter;

AppFooter.propTypes = {
  props: PropTypes.string,
};
