import React, { createContext, useEffect, useState } from 'react';
import { useUser } from 'context/UserContext';
import API from 'utils/API';
import crypto from 'crypto';

export const EssayContext = createContext({});

const EssayProvider = props => {
  const [activeEssay, setActiveEssay] = useState(null);
  const [essayId, setEssayId] = useState('');
  const [essayContent, setEssayContent] = useState('');
  const [draftId, setDraftId] = useState('');
  const [, setError] = useState(false);
  const { user } = useUser();

  const getInprogress = async () => {
    try {
      // 2nd param forces API to use new token
      // API getToken call returns undefined on first login
      const res = await API.get(`/essays/in_progress/${user.qid}`, {
        headers: { Authorization: `Token ${user.token}` },
      });

      if (res && res.data.data !== null) {
        const { id, attributes } = res.data.data;

        setActiveEssay({ id, ...attributes });
      }
    } catch (error) {
      setError(() => {
        throw error;
      });
    }
  };

  const timecheck = async () => {
    try {
      const res = await API.get(`/essays/timecheck/${user.qid}`);
      if (res && res.data.data !== null) {
        const { id, attributes } = res.data.data;

        if (activeEssay !== null && activeEssay.id === id) {
          // update time values
          setActiveEssay({ ...activeEssay, ...attributes });
        }
      }
    } catch (error) {
      setError(() => {
        throw error;
      });
    }
  };

  const saveDraft = async () => {
    await API.put(`/essays/${essayId}`, {
      essay: {
        content: essayContent,
        draft: true,
      },
    })
      .then(response => {
        const MAX_SALT = 100;
        const salt = Math.floor(
          (crypto.randomBytes(4).readUInt32LE(0) / 0xffffffff) * (MAX_SALT + 1),
        );
        const { data } = response;
        setDraftId(`${data.id}-${salt}`);
      })
      .catch(error => {
        setError(() => {
          throw error;
        });
      });
  };

  useEffect(() => {
    if (user.kind === 'student' && user.qid) {
      getInprogress();
    } else {
      // always unset
      setActiveEssay(null);
    }
  }, [user.qid]);

  return (
    <EssayContext.Provider
      value={{
        activeEssay,
        setActiveEssay,
        timecheck,
        saveDraft,
        essayContent,
        setEssayContent,
        draftId,
        setDraftId,
        setEssayId,
      }}
      {...props}
    />
  );
};

const useEssay = () => {
  // React.useContext allows this to work without issues in jest
  const context = React.useContext(EssayContext);
  return context;
};

export { EssayProvider, useEssay };
