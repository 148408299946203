/* eslint-disable */
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import React from 'react';
import Select, { components } from 'react-select';
import styled, { css } from 'styled-components';
import { flexbox, layout, space } from 'styled-system';

import { ReactComponent as IconDropdown } from 'assets/images/icons/dropdown.svg';
import { ReactComponent as IconX } from 'assets/images/icons/x.svg';
import theme from '../theme';
import Box from '../Box';

const optionStyles = css`
  display: flex;
  position: relative;
  align-items: center;
  height: 48px;
  padding-right: ${theme.space[3]}px;
  padding-left: ${theme.space[3]}px;
  background-color: ${theme.colors.white};
  font: ${theme.fontSizes[1]}px ${theme.fonts.main};
`;

const StyledBox = styled(Box)`
  min-width: 200px;
`;

const StyledIconDropdown = styled(IconDropdown)`
  width: 16px;
  fill: #8fb0d4;
`;

const StyledIconX = styled(IconX)`
  width: 16px;
  fill: currentColor;
  margin-left: 6px;
`;

const StyledLabel = styled.label(
  ({ theme }) => css`
    width: 100%;
    font: ${theme.fontSizes[0]}px ${theme.fonts.main};
    font-weight: bold;
    display: block;
    color: ${theme.colors.text};
  `,
  space,
);

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledIconDropdown />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = props => {
  return (
    <components.ClearIndicator {...props}>
      <StyledIconX />
    </components.ClearIndicator>
  );
};

const MultiValueRemove = props => {
  return (
    <components.MultiValueRemove {...props}>
      <StyledIconX />
    </components.MultiValueRemove>
  );
};

const StyledSpinner = styled.svg`
  width: 16px;
  animation: spin 1.2s infinite linear;
  position: relative;
  top: 14px; // reposition because we set align-items: flex-start to handle variable height in multiple option support

  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }
`;

const LoadingIndicator = ({ cx, ...props }) => {
  return (
    <StyledSpinner
      className={cx()}
      version="1.1"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="14"
        cy="14"
        fill="none"
        r="13"
        strokeDasharray="87.96452"
        strokeDashoffset="17.592904"
        strokeWidth="2"
        stroke="#adb8be"
      ></circle>
    </StyledSpinner>
  );
};

LoadingIndicator.propTypes = {
  cx: PropTypes.func,
};

const DropdownContext = React.createContext({});

const StyledSelect = styled(Select)(
  () => css`
    .react-select-2-dummyInput {
      position: absolute;
      width: 100%;
      height: 100%;
      transform: none;
      pointer-events: none;
    }
  `,
  flexbox,
  layout,
  space,
);

const Dropdown = ({
  required,
  id,
  isMulti,
  disabled,
  isSearchable,
  isClearable = false,
  name,
  onChange,
  ...props
}) => {
  let components = {
    DropdownIndicator,
    LoadingIndicator,
    ClearIndicator,
  };
  if (isMulti) {
    merge(components, { MultiValueRemove });
  }

  //spacing props
  const { mt, pt } = props;
  const { mx } = props;
  const { zIndex } = props;
  delete props.mt;
  delete props.pt;
  delete props.zIndex;
  const topSpacing = { mt, pt };

  return (
    // Use Context to pass additional props down to ValueContainer
    // because supplying a changing instance of ValueContainer with
    // these props causes issues with react-select's focus state
    // https://github.com/quipper/quipper/issues/22409
    <DropdownContext.Provider
      value={{
        required,
        id,
        value: props.value,
      }}
    >
      <StyledBox zIndex={zIndex} {...props}>
        {props.label && (
          <StyledLabel {...topSpacing} mb="2">
            {props.label}
          </StyledLabel>
        )}
        <StyledSelect
          name={name}
          inputId={id}
          data-testid="select"
          className={disabled && 'disabled'}
          components={components}
          isClearable={isClearable}
          styles={{
            input: () => css`
              padding-top: 8px;
              padding-left: 4px;
            `,
            control: (_, state) => css`
              display: flex;
              position: relative;
              min-height: 48px;
              border: 1px solid ${theme.colors.border};
              border-radius: 4px;
              background-color: #f8f9f9;
              transition: border-color 0.15s ease-in-out;

              &:hover {
                border-color: ${theme.colors.accent};
              }

              ${state.isDisabled &&
                css`
                  background-color: #e5ecf4;
                  border: none;
                `}

              ${state.isFocused &&
                css`
                  border-color: ${theme.colors.accent};
                  box-shadow: 0 0 0 1px ${theme.colors.accent};
                `}

              ${state.menuIsOpen &&
                css`
                  border-color: ${theme.colors.accent};
                  border-bottom-right-radius: 0;
                  border-bottom-left-radius: 0;
                  box-shadow: 0 0 0 1px ${theme.colors.accent};

                  svg {
                    transform: scale(-1);
                  }
                `}
              ${!state.menuIsOpen &&
                css`
                  svg {
                    transform: scale(1);
                  }
                `}
            `,
            dropdownIndicator: () => css`
              padding: ${theme.space[2]}px;
              color: ${theme.colors.greyblue};
              padding-top: 16px;
            `,
            indicatorSeparator: () => ({}),
            menu: () => css`
              position: absolute;
              top: calc(100% - 1px);
              z-index: 1;
              width: 100%;
              align-items: flex-start;
            `,
            singleValue: (provided, state) => css`
              ${state.isDisabled
                ? css`
                    color: ${theme.colors.tertiary};
                  `
                : css`
                    ${provided}
                  `}
            `,
            multiValue: () => css`
              background-color: #e3ecf5;
              padding: 6px;
              margin-top: 8px;
              margin-right: 10px;
              display: flex;
            `,
            multiValueLabel: () => css`
              color: #6b8fbc;
              font-weight: bold;
            `,
            multiValueRemove: () => css`
              color: ${theme.colors.greyblue};
              display: flex;
              align-items: center;
            `,
            clearIndicator: () => css`
              color: ${theme.colors.secondary};
              display: flex;
              flex-direction: row;
              padding: ${theme.space[2]}px;
              height: 48px;
            `,
            indicatorsContainer: () => css`
              display: flex;
              align-items: flex-start;
              justify-content: center;
            `,
            menuList: provided => css`
              ${provided}
              padding-top: 0;
              padding-bottom: 0;
              border: 1px solid ${theme.colors.accent};
              border-top-width: 0;
              border-bottom-right-radius: 4px;
              border-bottom-left-radius: 4px;
              box-shadow: -1px 0 0 0 ${theme.colors.accent},
                1px 0 0 0 ${theme.colors.accent},
                0 1px 0 0 ${theme.colors.accent};
              max-height: 200px; // only show first 4 options
            `,
            option: (_, state) => css`
              ${optionStyles}
              ${state.isSelected || state.isFocused
                ? css`
                    background-color: #e3ecf5;
                  `
                : css`
                    &:hover {
                      background-color: #d6f5ff;
                    }
                  `}
            `,
            noOptionsMessage: () => optionStyles,
            placeholder: provided => css`
              ${provided}
              color: #8fb0d4;
              font: ${theme.fontSizes[1]}px ${theme.fonts.main};
            `,
            valueContainer: provided => css`
              ${provided}
              padding-left: ${theme.space[3]}px;
              padding-right: ${theme.space[3]}px;
              padding-bottom: 8px;
              padding-top: 0px;
              font: ${theme.fontSizes[1]}px ${theme.fonts.main};
            `,
          }}
          isMulti={isMulti}
          isDisabled={props.isDisabled}
          isSearchable={isSearchable}
          options={props.options}
          onChange={onChange}
          value={props.value}
          placeholder={props.placeholder}
        />
      </StyledBox>
    </DropdownContext.Provider>
  );
};

Dropdown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ),
    PropTypes.string,
  ]),
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  mt: PropTypes.string,
  pt: PropTypes.string,
  mx: PropTypes.string,
  zIndex: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
};

Dropdown.defaultProps = {
  isSearchable: false,
};

export default Dropdown;
