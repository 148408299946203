import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled, { createGlobalStyle, css } from 'styled-components';
import { layout, space } from 'styled-system';

import { ReactComponent as IconSchedule } from 'assets/images/icons/schedule.svg';
import { groupStyleProps, stylePropsShape } from '../utils';

const DatePickerWrapper = styled.div(
  ({ theme, isDisabled }) => css`
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid currentColor;
    border-radius: 4px;
    background-color: #f8f9f9;
    color: ${theme.colors.border};
    transition: color 0.15s ease-in-out;

    &:hover,
    &.--focus {
      color: ${theme.colors.accent};
    }

    &::before {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      box-shadow: 0 0 0 2px ${theme.colors.accent};
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
      pointer-events: none;
      content: '';
    }

    &.--focus::before {
      opacity: 1;
    }

    & > * {
      width: 100%;
    }

    ${isDisabled &&
      css`
        background-color: ${theme.colors.grey};
        border: none;
        &:hover,
        &.--focus {
          color: ${theme.colors.border};
        }
      `}
  `,
  layout,
  space,
);

const StyledIconSchedule = styled(IconSchedule)(
  ({ theme }) => css`
    width: 24px;
    height: 100%;
    margin-left: ${theme.space[3]}px;
    fill: currentColor;
  `,
);

const StyledReactDatePicker = styled(ReactDatePicker)(
  ({ theme, height }) => css`
    width: 100%;
    height: ${height || '48px'};
    background: none;
    border: none;
    outline: none;
    padding-left: ${theme.space[3]}px;
    padding-right: ${theme.space[3]}px;
    color: ${theme.colors.text};
    font: ${theme.fontSizes[1]}px ${theme.fonts.main};

    &:disabled {
      color: ${theme.colors.tertiary};
    }
  `,
);

const DatePickerPopperStyles = createGlobalStyle`
  .react-datepicker-popper {
    transform: translate3d(-2px, -245px, 0px) !important;
    z-index: 10 !important;
  }
`;

const DatePicker = ({ styleProps, value, ...props }) => {
  const [hasFocus, setHasFocus] = useState(false);
  const handleFocus = useCallback(() => setHasFocus(true), []);
  const handleBlur = useCallback(() => setHasFocus(false), []);
  const { disabled } = props;

  const heightWithUnit =
    typeof styleProps.height === 'number'
      ? `${styleProps.height}px`
      : styleProps.height;

  const now = new Date();
  const yearLimit = now.getDate() + 365;
  return (
    <DatePickerWrapper
      data-testid="wrapper-date-picker"
      className={hasFocus && '--focus'}
      isDisabled={disabled}
      {...styleProps}
    >
      <DatePickerPopperStyles />
      <StyledIconSchedule />
      <StyledReactDatePicker
        onFocus={handleFocus}
        onBlur={handleBlur}
        height={heightWithUnit}
        selected={value}
        maxDate={now.setDate(yearLimit)}
        {...props}
      />
    </DatePickerWrapper>
  );
};

DatePicker.propTypes = {
  styleProps: stylePropsShape,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  autoComplete: PropTypes.oneOf(['on', 'off']),
};

export default groupStyleProps(DatePicker);
