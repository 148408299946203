import '@material/ripple/dist/mdc.ripple.css';
import { MDCRipple } from '@material/ripple';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { color, flexbox, layout, space, variant } from 'styled-system';

import Text from '../Text';

const StyledButton = styled.button(
  ({ theme }) => css`
    display: inline-block;
    position: relative;
    height: 48px;
    border: 1px solid ${theme.colors.accent};
    border-radius: 4px;
    background-color: ${theme.colors.accent};
    padding-right: ${theme.space[4]}px;
    padding-left: ${theme.space[4]}px;
    color: ${theme.colors.white};
    text-align: center;
    text-decoration: none;
    transition: border-color 0.2s, background-color 0.2s;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: #15b3ff;
    }

    &:focus {
      outline: none;
    }

    &.delete {
      border: none;
      background-color: ${theme.colors.danger};
    }

    &.hidden {
      visibility: hidden;
    }

    &:disabled {
      border-color: #e3ecf5;
      background-color: #e3ecf5;
      color: #8fb0d4;
      cursor: default;

      &::before {
        opacity: 0;
      }
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-shadow: 0 2px 4px 1px rgba(128, 141, 150, 0.2);
      transition: opacity 0.2s;
      content: '';
    }

    /* Manually override styles so we don't have to install
     * node-sass to do it */
    &.mdc-ripple-upgraded--background-focused::before,
    &.mdc-ripple-surface:hover::before {
      opacity: 0 !important;
    }
  `,
  variant({
    variants: {
      text: {
        border: 'none',
        background: 'none',
        '&:not(:disabled)': {
          '&:hover, &:focus': {
            background: '#e9eeef',
          },
        },
        color: 'text',
      },
      secondary: {
        borderWidth: '1px',
        borderColor: 'border',
        background: 'white',
        color: 'text',
        '&:not(:disabled)': {
          '&:hover, &:focus': {
            background: 'white',
          },
        },
        '&::before': {
          opacity: 0,
        },
        '&:hover:not(:disabled)::before': {
          boxShadow: '0 4px 12px 0 rgba(0,0,0,.15)',
          opacity: 1,
        },
      },
      tertiary: {
        border: 'none',
        background: 'none',
        '&:not(:disabled)': {
          '&:hover, &:focus': {
            background: '#e9eeef',
          },
        },
        color: 'secondary',
        '&::before': {
          'box-shadow': 'none',
        },
      },
      'outline--primary': {
        borderWidth: '1px',
        borderColor: 'accent',
        background: 'white',
        color: 'accent',
        '&:not(:disabled)': {
          '&:hover, &:focus': {
            color: 'white',
          },
        },
      },
    },
  }),
  color,
  flexbox,
  layout,
  space,
);

const StyledText = styled(Text)`
  display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
`;

const Button = ({ children, fontSize, childrenStyle = {}, ...props }) => {
  const enableRipple = props.variant !== 'secondary' && !props.disabled;
  const buttonRef = useRef(null);

  useEffect(() => {
    if (enableRipple && buttonRef.current) {
      MDCRipple.attachTo(buttonRef.current);
    }
  }, [enableRipple, buttonRef]);

  return (
    <StyledButton
      className={enableRipple ? 'mdc-ripple-surface' : ''}
      ref={buttonRef}
      {...props}
    >
      <StyledText
        color="currentColor"
        fontSize={fontSize || '1'}
        fontWeight="bold"
        style={childrenStyle}
      >
        {children}
      </StyledText>
    </StyledButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  fontSize: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  childrenStyle: PropTypes.object,
};

export default Button;
