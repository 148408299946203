import PropTypes from 'prop-types';
import React, { useState } from 'react';

import styled, { css } from 'styled-components';
import useWindowSize from 'utils/Hooks';

const DESKTOP_WIDTH = 920;
const StyledTr = styled.tr(
  ({ theme }) => css`
    &:not(:first-child) {
      border-top: 1px solid ${theme.colors.grey};
    }

    &[aria-expanded='false'] {
      td:not(:first-child) {
        display: none;

        @media screen and (min-width: ${theme.breakpoints[1]}) {
          display: table-cell;
        }
      }

      td.details {
        .label {
          display: none;
        }
        display: none;
      }
    }
    &[aria-expanded='true'] {
      td.details .label {
        display: none;
      }
      .chevron {
        display: none;
      }
      @media only screen and (min-width: ${theme.breakpoints[0]}) {
        td.details {
          display: block;
        }
      }
      @media only screen and (min-width: ${theme.breakpoints[1]}) {
        td.details {
          display: none;
        }
      }
    }

    @media only screen and (min-width: ${theme.breakpoints[1]}) {
      &:hover {
        > td {
          background: ${theme.colors.highlight};
        }
      }
    }

    &.disabled {
      background: ${theme.colors.background};

      > td {
        cursor: default;
        color: ${theme.colors.secondary} !important;
        > div {
          color: ${theme.colors.secondary} !important;
          span {
            color: ${theme.colors.secondary} !important;
          }
          > .badge {
            background-color: ${theme.colors.secondary} !important;
          }
        }
        > span {
          color: ${theme.colors.secondary} !important;
        }
      }

      &:hover > td {
        background: transparent;
      }
    }
  `,
);

// NOTES on some params
// - columnEdge determines the column index where the
// chevron should appear. it's not as straightforward
// as getting the length of the children since we're using
// a hidden column(for desktop/mobile layout)
// - clickable is a prop that allows visual cue (chevron arrow)
// to be delegated on the main table render loop
const Tr = ({
  children,
  columnEdge,
  clickable = false,
  rowHandler = null,
  allowClick = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const size = useWindowSize();
  const isDesktop = size?.width >= DESKTOP_WIDTH;

  return (
    <StyledTr
      aria-expanded={isDesktop ? false : isOpen}
      onClick={() => setIsOpen(state => !state)}
      rowHandler={rowHandler}
      className={!clickable && 'disabled'}
    >
      {React.Children.map(
        children,
        (child, i) =>
          child &&
          React.cloneElement(child, {
            ...child.props,
            columnIndex: i,
            isOpen,
            lastColumn: clickable && i === columnEdge,
            onClick: isDesktop || allowClick ? rowHandler : null,
          }),
      )}
    </StyledTr>
  );
};

Tr.propTypes = {
  children: PropTypes.node,
  rowHandler: PropTypes.func,
  className: PropTypes.string,
  clickable: PropTypes.bool,
  columnEdge: PropTypes.number,
  allowClick: PropTypes.bool,
};

export default Tr;
