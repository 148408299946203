import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import Card from '../Card';
import Text from '../Text';

const StyledCard = styled(Card)(
  ({ theme }) =>
    css`
      width: 100%;
      overflow: hidden;
      overflow-x: auto;
      text-align: left;

      tr + tr {
        border-top: 1px solid ${theme.colors.grey};
      }

      th,
      td {
        font-size: ${theme.fontSizes[1]}px;
        padding-right: ${theme.space[4]}px;
        vertical-align: middle;

        &:first-child {
          padding-left: ${theme.space[4]}px;
        }
      }

      th {
        background-color: ${theme.colors.grey};
        height: 60px;
      }

      td {
        height: 64px;
      }
    `,
);

const Table = ({ children, emptyMessage, isEmpty, ...props }) => {
  return isEmpty ? (
    <Card
      display="flex"
      height="40vh"
      width="100%"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <Text>
        <FormattedMessage id={emptyMessage} />
      </Text>
    </Card>
  ) : (
    <StyledCard as="table" {...props} display="table">
      {children}
    </StyledCard>
  );
};

Table.defaultProps = {
  emptyMessage: 'common.empty',
  isEmpty: true,
};

Table.propTypes = {
  children: PropTypes.node,
  isEmpty: PropTypes.bool.isRequired,
  emptyMessage: PropTypes.string.isRequired,
};

export default Table;
