import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import API from 'utils/API';
import { getToken, setToken } from 'utils/AuthToken';
import { getUserMode } from 'utils/UserMode';

const UserContext = React.createContext({});

const UserProvider = ({ onLoad, ...props }) => {
  const userMode = getUserMode();

  const initialUserState = useMemo(() => ({ kind: userMode, token: null }), [
    userMode,
  ]);
  const [user, setUser] = useState(initialUserState);
  const [userFilters, setUserFilters] = useState({});
  const STORED_FILTER_KEY = useMemo(() => `filter:${user.id}`, [user.id]);
  const FILTER_SET = ['class', 'status', 'sortBy'];

  const setUserWrapped = useCallback(
    _user => {
      setUser(_user);
      onLoad(_user);

      if (_user.token && _user.token !== getToken()) {
        setToken(_user.token);
      }
    },
    [onLoad, setUser],
  );

  useEffect(() => {
    if (getToken()) {
      API.post('/auth', {
        auth: { token: getToken(), kind: userMode },
      })
        .then(response => {
          setUserWrapped(response.data);
        })
        .catch(() => {
          setUserWrapped(initialUserState);
        });
    } else {
      onLoad(initialUserState);
    }
  }, [userMode, initialUserState, setUserWrapped, onLoad]);

  const getUserFilters = () => {
    // retrieve userFilter if exist
    const filters = {};
    FILTER_SET.forEach(filter => {
      const userFilter = localStorage.getItem(`${STORED_FILTER_KEY}:${filter}`);
      if (userFilter !== null) {
        filters[filter] = JSON.parse(userFilter);
      }
    });
    return filters;
  };

  useEffect(() => {
    setUserFilters(getUserFilters());
  }, [user.id]);

  const setUserFilter = (filterKey, filterValue) => {
    localStorage.setItem(
      `${STORED_FILTER_KEY}:${filterKey}`,
      JSON.stringify(filterValue),
    );
    setUserFilters(getUserFilters());
  };

  const applyUserFilters = filters => {
    // eslint-disable-next-line no-restricted-syntax, no-unused-vars
    for (const [filterKey, filterValue] of Object.entries(filters)) {
      setUserFilter(filterKey, filterValue);
    }
  };

  const clearUserFilters = () => {
    FILTER_SET.forEach(filter =>
      localStorage.removeItem(`${STORED_FILTER_KEY}:${filter}`),
    );
    setUserFilters({});
  };

  const logOut = () => {
    setToken(null);
    setUserWrapped(initialUserState);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        logIn: setUserWrapped,
        logOut,
        userFilters,
        setUserFilter,
        applyUserFilters,
        clearUserFilters,
      }}
      {...props}
    />
  );
};

UserProvider.propTypes = {
  onLoad: PropTypes.func.isRequired,
};

const useUser = () => {
  const context = useContext(UserContext);
  return context;
};

export { UserProvider, useUser };
