import React from 'react';
import PropTypes from 'prop-types';

import DatePicker from './DatePicker';
import InputNumber from './InputNumber';
import InputPassword from './InputPassword';
import InputText from './InputText';
import InputCheckbox from './InputCheckbox';
import Textarea from './Textarea';

const Input = ({ type, ...props }) => {
  switch (type) {
    case 'date':
      return <DatePicker {...props} />;
    case 'textarea':
      return <Textarea {...props} />;
    case 'password':
      return <InputPassword {...props} />;
    case 'number':
      return <InputNumber {...props} />;
    case 'checkbox':
      return <InputCheckbox {...props} />;
    case 'text':
    default:
      return <InputText {...props} />;
  }
};

Input.propTypes = {
  type: PropTypes.oneOf([
    'date',
    'email',
    'number',
    'password',
    'search',
    'text',
    'textarea',
  ]),
};

export default Input;
