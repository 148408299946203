import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { InputWrapper, StyledInput } from './styles';
import { groupStyleProps, stylePropsShape } from '../utils';

const InputText = ({ styleProps, ...props }) => {
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const { disabled } = props;
  const { className } = props;

  return (
    <InputWrapper
      isFocused={inputHasFocus}
      isDisabled={disabled}
      className={className === 'hidden' ? 'hidden' : ''}
      {...styleProps}
    >
      <StyledInput
        data-testid="input-text"
        type="text"
        isDisabled={disabled}
        onFocus={() => setInputHasFocus(true)}
        onBlur={() => setInputHasFocus(false)}
        {...props}
      />
    </InputWrapper>
  );
};

InputText.propTypes = {
  styleProps: stylePropsShape,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default groupStyleProps(InputText);
