import { props } from '@styled-system/should-forward-prop';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line react/display-name
export const groupStyleProps = component => componentProps => {
  const styleProps = pick(componentProps, props);
  const otherProps = omit(componentProps, props);

  return React.createElement(component, {
    styleProps,
    ...otherProps,
  });
};

export const stylePropsShape = PropTypes.shape(
  Object.assign({}, ...props.map(prop => ({ [prop]: PropTypes.string }))),
);
