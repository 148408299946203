import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useTheme } from 'context/ThemeContext';
import Box from '../Box';

const StyledBox = styled(Box)(
  () => css`
    .filter {
      width: 280px;
    }

    &.two-col {
      main {
        margin-left: 280px;
      }

      footer {
        width: calc(98.5vw - 280px) !important;
        margin-left: 280px !important;
      }
    }

    &.one-col {
      > div > header {
        > div {
          max-width: 1200px;
        }
      }

      main {
        margin-left: unset;
        width: 100%;
      }

      footer {
        width: 100%;
        margin-left: 0;
        z-index: 1;
      }
    }
  `,
);

const Wrapper = ({ children, ...props }) => {
  const { columns } = useTheme();
  return (
    <StyledBox className={columns === 1 ? 'one-col' : 'two-col'} {...props}>
      {children}
    </StyledBox>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node,
};

export default Wrapper;
