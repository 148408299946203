import styled, { css } from 'styled-components';
import {
  color,
  flexbox,
  layout,
  position,
  space,
  typography,
  background,
  border,
} from 'styled-system';

const Box = styled.div(
  ({ theme }) => css`
    ul {
      font: ${theme.fontSizes.body}px ${theme.fonts.main};
    }
  `,
  color,
  flexbox,
  layout,
  space,
  position,
  typography,
  background,
  border,
  ({ flexFlow }) => {
    return { flexFlow };
  },
  ({ borderTop }) => {
    return { borderTop };
  },
  ({ borderRadius }) => {
    return { borderRadius };
  },
  ({ backgroundSize }) => {
    return { backgroundSize };
  },
  ({ transition }) => {
    return { transition };
  },
);

export default Box;
