import omit from 'lodash/omit';
import pick from 'lodash/pick';
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Box from '../Box';

const BoxOuter = styled(Box)(({ theme }) => {
  const { minWidth } = theme.breakpoints;

  return css`
    min-width: ${minWidth};

    @media screen and (max-width: ${minWidth}) {
      margin-right: calc(50% - (${minWidth} / 2));
      margin-left: calc(50% - (${minWidth} / 2));
    }
  `;
});

const BoxWide = ({ children, ...props }) => {
  const innerFlexProps = [
    'alignItems',
    'alignContent',
    'justifyItems',
    'justifyContent',
    'flexWrap',
    'flexDirection',
  ];
  const otherProps = omit(props, innerFlexProps);

  return (
    <BoxOuter
      display="flex"
      width="100vw"
      px="3"
      mx="calc(-1*(100vw - 100%)/2)"
      {...otherProps}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        width="100%"
        // maxWidth="1200px"
        mx="auto"
        {...pick(props, innerFlexProps)}
      >
        {children}
      </Box>
    </BoxOuter>
  );
};

BoxWide.propTypes = {
  children: PropTypes.node,
};

export default BoxWide;
