import axios from 'axios';
import merge from 'lodash/merge';

import { getToken } from 'utils/AuthToken';

export const payloadDefaults = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

const mergeWithDefaultConfig = config => {
  const token = getToken();
  delete payloadDefaults.headers?.Authorization;
  return merge(payloadDefaults, {
    headers: token && { Authorization: `Token ${token}` },
    ...config,
  });
};

const prefixApiPath = path => `/api${path}`;

export default class API {
  static get(path, config) {
    return axios.get(prefixApiPath(path), mergeWithDefaultConfig(config));
  }

  static post(path, data, config) {
    return axios.post(
      prefixApiPath(path),
      data,
      mergeWithDefaultConfig(config),
    );
  }

  static put(path, data, config) {
    return axios.put(prefixApiPath(path), data, mergeWithDefaultConfig(config));
  }

  static delete(path, config) {
    return axios.delete(prefixApiPath(path), mergeWithDefaultConfig(config));
  }
}
