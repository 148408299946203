import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import Modal from 'common/Modal';
import { Button, Box, Text } from 'q-system';

const StudentEssayLeaveConfirmationModal = ({ onConfirm, onCancel }) => {
  return (
    <Modal
      title={<FormattedMessage id="submission.leave_page" />}
      display="flex"
      flexDirection="column"
      alignItems="left"
      data-testid="essay-leave-modal"
    >
      <Text
        as="p"
        mt="1"
        fontSize="1"
        textAlign="left"
        display="flex"
        flexDirection="column"
      >
        <FormattedHTMLMessage id="submission.leave_confirmation_modal" />
      </Text>
      <Box display="flex" justifyContent="center" width="100%" mt="5">
        <Button variant="secondary" onClick={onCancel}>
          <FormattedMessage id="common.cancel" />
        </Button>
        <Button ml="2" onClick={onConfirm}>
          <FormattedMessage id="submission.save_and_leave" />
        </Button>
      </Box>
    </Modal>
  );
};

StudentEssayLeaveConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default StudentEssayLeaveConfirmationModal;
