import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PageVisibility from 'react-page-visibility';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Icon from 'common/Icon';
import Timer2 from 'common/Timer2';
import { useEssay } from 'context/EssayContext';
import { Box } from 'q-system';
import API from 'utils/API';
import { localizePath } from 'utils/I18n';

const StyledTimer = styled(Box)(
  ({ theme }) => css`
    background: ${theme.colors.white};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
    top: 18px;
    right: 200px;
    border: 1px solid ${theme.colors.grey};
    border-radius: 32px;
    padding: ${theme.space[2]}px ${theme.space[4]}px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  `,
);

const EssayTimer = ({ beforeCallback, callback, endCallback, ...props }) => {
  const { activeEssay, setActiveEssay, timecheck } = useEssay();
  const [, setError] = useState(false);
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  const postEssay = async () => {
    try {
      await API.put(`/essays/${activeEssay.id}`, {
        essay: {
          content: localStorage.getItem(`essays:${activeEssay.id}`),
          draft: false,
        },
      });
      setActiveEssay(null);
    } catch (error) {
      setError(() => {
        throw error;
      });
    }
  };

  const handleTimeout = path => {
    const re = new RegExp(`essays/${activeEssay.id}`);
    if (re.test(path)) {
      beforeCallback();
      history.push(`${path}/timesup`);
    }
    postEssay();
    endCallback();
  };

  const gotoEssay = () => {
    // using location.href is not as smooth as history.push
    // but this ensures that we get the remaining time updated
    // when the student goes to the essay page
    window.location.href = localizePath(`/essays/${activeEssay.id}`);
  };

  const handleVisibility = () => {
    if (!document.hidden) {
      timecheck();
    }
  };

  if (activeEssay !== null) {
    return (
      <>
        <PageVisibility onChange={handleVisibility} />
        <StyledTimer data-testid="wrapper-essay" onClick={gotoEssay} {...props}>
          <Icon
            type="time"
            mr="2"
            tooltip={f({ id: 'timed_essay.timer_tooltip' })}
            minutesremain={activeEssay.timer_remaining}
            timelimit={activeEssay.assignment.time_limit}
            inprogress={activeEssay !== null}
          />
          <Timer2
            minutes={activeEssay.timer_remaining}
            seconds={activeEssay.seconds_remaining}
            fontWeight="bold"
            callback={callback}
            callbackTrigger={10}
            endCallback={handleTimeout}
            start
          />
        </StyledTimer>
      </>
    );
  }

  return null;
};

EssayTimer.propTypes = {
  beforeCallback: PropTypes.func,
  callback: PropTypes.func,
  endCallback: PropTypes.func,
};

export default EssayTimer;
