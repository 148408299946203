import React, { createContext, useState } from 'react';

export const ThemeContext = createContext({});

export const ThemeContextProvider = props => {
  const [columns, setColumns] = useState(2);

  return <ThemeContext.Provider value={{ setColumns, columns }} {...props} />;
};

export const useTheme = (columns = 1) => {
  const context = React.useContext(ThemeContext);
  context.setColumns(columns);
  return context;
};
