/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Text } from 'q-system';
import PropTypes from 'prop-types';
import { setInterval, clearInterval } from 'worker-timers';

const StyledText = styled(Text)(
  ({ theme }) => css`
    &.warn {
      color: ${theme.colors.danger};
    }
  `,
);

const Timer2 = ({
  minutes,
  seconds = 0,
  start = false,
  callback = null,
  callbackTrigger = 10,
  endCallback = null,
  ...props
}) => {
  const defaultValue = '00:00:00';
  const [timeRemaining, setTimeRemaining] = useState(defaultValue);
  const [warningColor, setWarningColor] = useState(false);
  let counter = minutes === undefined ? seconds : minutes * 60 + seconds;
  let intervalId;
  let hrs = minutes > 60 ? parseInt(minutes / 60) : 0;
  let mins = minutes > 60 ? minutes % 60 : minutes;
  let hr_str;
  let min_str;
  let sec_str = '';

  useEffect(() => {
    const countdown = () => {
      // eslint-disable-next-line no-param-reassign
      counter -= 1;
      if (mins === 0 && hrs > 0 && seconds === 0) {
        hrs -= 1;
        mins = 59;
        seconds = 59;
      } else if (seconds === 0) {
        seconds = 59;
        --mins; // everytime your deducting a value, reset the second value
      } else {
        --seconds;
      }

      if (counter <= 0) {
        clearInterval(intervalId);
        setTimeRemaining(defaultValue);
      } else {
        hr_str = `${hrs}`.padStart(2, '0');
        min_str = `${mins}`.padStart(2, '0');
        sec_str = `${seconds}`.padStart(2, '0');
        setTimeRemaining(`${hr_str}:${min_str}:${sec_str}`);
      }

      if (
        callback &&
        mins === parseInt(callbackTrigger, 10) &&
        hrs === 0 &&
        seconds === 0
      ) {
        callback();
      }

      if (mins <= callbackTrigger - 1 && hrs === 0) {
        //deduct 1 to account for full seconds clock
        setWarningColor(true);
      }

      if (
        endCallback &&
        (mins < 0 || (hrs === 0 && mins === 0 && seconds === 0))
      ) {
        endCallback(window.location.pathname);
      }
    };

    if (start) {
      intervalId = setInterval(countdown, 1000);
    }

    // clear interval on unmount
    return () => clearInterval(intervalId);
  }, [minutes, seconds]);

  return (
    <StyledText
      data-testid="timer"
      {...props}
      className={warningColor && 'warn'}
    >
      {timeRemaining}
    </StyledText>
  );
};

Timer2.propTypes = {
  start: PropTypes.bool,
  minutes: PropTypes.number,
  seconds: PropTypes.number,
  callback: PropTypes.func,
  callbackTrigger: PropTypes.number, // minutes mark when callback will be triggered
  endCallback: PropTypes.func,
};

export default Timer2;
