import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { getUrlLocaleMatch, localizePath } from 'utils/I18n';

export const getPlainPath = path => {
  const urlLocaleMatch = getUrlLocaleMatch(path);
  const plainPath = urlLocaleMatch ? urlLocaleMatch[2] : path;

  return plainPath || '/';
};

export const RouteGroup = ({ children }) => {
  return <>{children}</>;
};

RouteGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export const LocalizedLink = ({ to, ...props }) => {
  return React.createElement(Link, { to: localizePath(to), ...props });
};

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
};
