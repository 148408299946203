import styled, { css } from 'styled-components';
import { flexbox, layout, space } from 'styled-system';

/* Performant box-shadow animation
 * https://tobiasahlin.com/blog/how-to-animate-box-shadow/
 */
const boxShadow = ({ theme, isFocused }) => css`
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    box-shadow: 0 0 0 2px ${theme.colors.accent};
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
    pointer-events: none;
    content: '';
  }

  ${isFocused &&
    css`
      &::before {
        opacity: 1;
      }
    `}
`;

export const InputWrapper = styled.div(
  ({ theme, isFocused, isDisabled }) => css`
    display: inline-flex;
    position: relative;
    height: 48px;
    border-radius: 4px;
    border: 1px solid currentColor;
    background-color: #f8f9f9;
    color: ${theme.colors.border};
    transition: 0.15s border-color ease-in-out;

    &:hover {
      border-color: ${theme.colors.accent};
    }

    &.hidden {
      height: 1px;
      color: white;
      border: solid white;
      background: white;
      > ::placeholder {
        color: white;
      }
      > input {
        color: white;
      }
    }

    ${isFocused &&
      css`
        background-color: ${theme.colors.white};
        color: ${theme.colors.accent};
      `}

    ${isDisabled &&
      css`
        border-color: ${theme.colors.grey};
        background-color: ${theme.colors.grey};
      `}
  `,
  boxShadow,
  flexbox,
  layout,
  space,
);

const autofillBgColorDisable = css`
  @keyframes autofill {
    to {
      background: transparent;
    }
  }

  &:-webkit-autofill {
    animation-name: autofill;
    animation-fill-mode: forwards;
  }
`;

export const StyledInput = styled.input(
  ({ theme }) => css`
    ${autofillBgColorDisable}

    width: 100%;
    height: 100%;
    border: none;
    background: none;
    padding-right: ${theme.space[3]}px;
    padding-left: ${theme.space[3]}px;
    color: ${theme.colors.text};
    font: ${theme.fontSizes[1]}px ${theme.fonts.main};

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #8fb0d4;
      font: ${theme.fontSizes[1]}px ${theme.fonts.main} 3;
    }

    &:disabled {
      color: ${theme.colors.tertiary};
    }
  `,
);
