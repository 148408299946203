import cookie from 'cookie_js';
import { getRootDomain } from 'utils/Browser';
import { getUserMode } from 'utils/UserMode';

const QUIPPER_AUTH_TOKEN = 'quipper_auth_token';
const LEARN_AUTH_TOKEN = 'learn_auth_token';

export const getToken = () => {
  if (getUserMode() === 'teacher') {
    return cookie.get(QUIPPER_AUTH_TOKEN);
  }

  return cookie.get(LEARN_AUTH_TOKEN);
};

export const setToken = token => {
  const tokenCookie =
    getUserMode() === 'teacher' ? QUIPPER_AUTH_TOKEN : LEARN_AUTH_TOKEN;
  const options = {
    domain: getRootDomain(),
    path: '/',
  };

  if (token === null) {
    cookie.removeSpecific(tokenCookie, options);
  } else {
    cookie.set(tokenCookie, token, options);
  }
};
