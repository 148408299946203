import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ReactComponent as Spinner } from 'assets/images/icons/spinner.svg';
import { Box } from 'q-system';

const StyledBox = styled(Box)`
  position: absolute;
  margin: auto;
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 28px;
  margin: auto;
  animation: spin 1.2s infinite linear;

  @keyframes spin {
    to {
      transform: rotate(1turn);
    }
  }
`;

const Loader = ({ delay = 200 }) => {
  const [isAfterDelay, setIsAfterDelay] = useState(false);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setIsAfterDelay(true);
    }, delay);

    return () => {
      clearTimeout(delayTimeout);
    };
  }, [delay]);

  if (!isAfterDelay) {
    return null;
  }

  return (
    <StyledBox width="100%" height="100%" data-testid="wrap-loader">
      <StyledSpinner />
    </StyledBox>
  );
};

Loader.propTypes = {
  delay: PropTypes.number,
};

export default Loader;
