import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ReactComponent as Logo } from 'assets/images/logos/logo-quipper-horizontal.svg';
import { Box, BoxWide, Text } from 'q-system';

import Avatar from 'common/Avatar';
import Flash from 'common/Flash';
import EssayTimer from 'student/common/EssayTimer';
import StudentEssayLeaveConfirmationModal from 'student/essay/StudentEssayLeaveConfirmationModal';
import { useEssay } from 'context/EssayContext';
import { useUser } from 'context/UserContext';
import { useForm } from 'context/FormContext';
import { ModalContext } from 'utils/Modal';
import { getCurrentLocale } from 'utils/I18n';
import { getUserMode } from 'utils/UserMode';

const AuthHeader = styled(BoxWide)(
  ({ theme, userType, showContent }) => css`
    position: fixed;
    border-top: 4px solid;
    margin-left: 0px;
    width: 100%;

    ${userType === 'teacher' &&
      css`
        border-color: #0056b3;
      `}

    ${userType === 'student' &&
      css`
        border-color: ${theme.colors.accent};
      `}

    ${showContent &&
      css`
        min-height: 74px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
        z-index: 1000;
      `}
  `,
);

// this Filler makes the DOM think that
// AppHeader is not position:fixed and takes up space
// So we can use Flash inside AppHeader without any modification
const Filler = styled(Box)`
  height: 74px;
  min-height: 74px;
  visibility: hidden;
`;

const StyledLogo = styled(Logo)`
  height: 30px;
  fill: #00beff;
  cursor: pointer;
`;

const StyledText = styled(Text)`
  cursor: pointer;
`;

const AppHeader = ({ hasError }) => {
  const { user, logOut } = useUser();
  const [showTimeAlert, setShowTimeAlert] = useState(false);
  const [showTimesup, setShowTimesup] = useState(false);
  const showContent = user.token || hasError;
  const userDetails = user.details;
  const userId = user.id;
  const userKind = user.kind;
  const { formatMessage: f } = useIntl();
  const { hideModal, openModal } = useContext(ModalContext);

  const { saveDraft } = useEssay();
  const { isFormDirty, setIsFormDirty } = useForm();

  const handleLogOutClick = () => {
    if (isFormDirty) {
      switch (userKind) {
        case 'student':
          openModal(
            <StudentEssayLeaveConfirmationModal
              onCancel={() => hideModal()}
              onConfirm={async () => {
                await saveDraft();
                setIsFormDirty(false);
                hideModal();
                logOut();
              }}
            />,
          );
          break;
        case 'teacher':
          // eslint-disable-next-line no-case-declarations, no-alert
          const userResponse = window.confirm(f({ id: 'edit.prompt' }));
          if (userResponse) logOut();
          break;
        default:
          break;
      }
    } else {
      hideModal();
      logOut();
    }

    if (hasError) {
      window.location.href = `/${getCurrentLocale()}/login`;
    }
  };

  const history = useHistory();

  const handleHomeClick = () => {
    const localizedRoot = `/${getCurrentLocale()}/`;

    if (hasError) {
      window.location.href = localizedRoot;
    } else {
      history.push(localizedRoot);
    }
  };

  return (
    <>
      <AuthHeader
        data-testid="app-header"
        forwardedAs="header"
        userType={getUserMode()}
        showContent={showContent}
        justifyContent="space-between"
        alignItems="center"
        bg="white"
      >
        {showContent && (
          <>
            <StyledLogo data-testid="logo" onClick={handleHomeClick} />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              width="280px"
            >
              {getUserMode() === 'student' && (
                <EssayTimer
                  beforeCallback={() => setIsFormDirty(false)}
                  callback={() => setShowTimeAlert(true)}
                  endCallback={() => setShowTimesup(true)}
                  mr="3"
                />
              )}
              {userDetails && (
                <Avatar src={userDetails.profile_image_url} mr="3" />
              )}
              <StyledText
                onClick={handleLogOutClick}
                fontSize="1"
                fontWeight="bold"
              >
                <FormattedMessage id="header.log_out" />
              </StyledText>
            </Box>
          </>
        )}
      </AuthHeader>
      <Filler />
      {showContent && showTimeAlert && (
        <Flash
          type="warn"
          delta={`${userId}`}
          message={f({ id: 'timed_essay.warning.ending_soon' })}
          callback={() => setShowTimeAlert(false)}
        />
      )}
      {showContent && showTimesup && (
        <Flash
          type="alert"
          delta={`${userId}`}
          message={f({ id: 'timed_essay.timesup' })}
          callback={() => setShowTimesup(false)}
        />
      )}
    </>
  );
};

AppHeader.propTypes = {
  hasError: PropTypes.bool.isRequired,
};

export default AppHeader;
