import cookie from 'cookie_js';
import { getRootDomain } from 'utils/Browser';

export const USER_MODE = 'user_mode';

export const setDevelopmentUserMode = userMode =>
  cookie.set(USER_MODE, userMode, {
    domain: getRootDomain(),
    path: '/',
  });

export const getUserMode = () => {
  if (process.env.NODE_ENV === 'production') {
    if (window.location.origin.match(/essays-learn/)) {
      return 'student';
    }

    return 'teacher';
  }

  if (!cookie.get(USER_MODE)) {
    setDevelopmentUserMode('teacher');
  }

  return cookie.get(USER_MODE);
};
