import PropTypes from 'prop-types';
import React from 'react';
import { space, width } from 'styled-system';

import styled, { css } from 'styled-components';
import { ReactComponent as TimeSVG } from 'assets/images/icons/stopwatch.svg';

import 'common/Icon.css';

const baseStyle = () => css`
  width: 20px;
  height: 20px;
`;

const StyledTimeIcon = styled(TimeSVG)(
  space,
  width,
  ({ theme }) => css`
    ${baseStyle}
    fill: ${theme.colors.darkblue};

    & path#moving { visibility: hidden }
    & path#static { visibility: visible }

    &.active {
      path#moving { visibility: visible }
      path#static { visibility: hidden }
    }

    &.secondary {
      fill: ${theme.colors.greyblue};
    }

  `,
);

const Icon = ({
  type,
  timelimit,
  inprogress,
  tooltip,
  flow = 'bottom',
  style,
  ...props
}) => {
  if (type === 'time') {
    return (
      // eslint-disable-next-line react/no-unknown-property
      <span tooltip={tooltip} flow={flow} className="flex">
        <StyledTimeIcon
          data-testid="time-icon"
          data-tooltip={tooltip}
          className={[inprogress && 'active', style]}
          {...props}
        />
      </span>
    );
  }

  return null;
};

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  style: PropTypes.string,
  flow: PropTypes.string,
  tooltip: PropTypes.string,
  timelimit: PropTypes.number,
  minutesremain: PropTypes.number,
  inprogress: PropTypes.bool,
};
export default Icon;
