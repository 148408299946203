import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Box from '../Box';

const StyledSkeleton = styled(Box)(
  ({ theme }) => css`
    &.table {
      box-shadow: rgb(128 141 150 / 15%) 0px 0px 4px 1px;
      background: white;
    }
    .thead {
      height: 60px;
      background: ${theme.colors.grey};
      border-radius: 4px;
    }
    .tbody {
      background: ${theme.colors.grey};
      height: 20px;
      margin: ${theme.space[4]}px;
      position: relative;

      &:not(:first-child) {
        border-top: 1px solid ${theme.colors.grey};
      }
    }

    // list type skeleton
    > .item {
      padding: 20px 14px;
      position: relative;

      > div {
        &:first-child {
          width: 40%;
          margin-bottom: ${theme.space[3]}px;
        }
        background: ${theme.colors.grey};
        height: 20px;
        position: relative;
      }
    }

    // from qcreate-react WaveAnimation
    .wave {
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        270deg,
        rgba(255, 255, 255, 100),
        /**
         * Favor below over "transparent" or "rgba(0, 0, 0, 0)" because
         * these do not render correctly in browsers like Safari
         */
          rgba(255, 255, 255, 0) 48px
      );
      opacity: 0.4;
      animation: highlight 1.6s infinite linear;
    }
    @keyframes highlight {
      from {
        transform: translateX(0);
      }

      to {
        transform: translateX(100%);
      }
    }
  `,
);

const Skeleton = ({ type = 'table' }) => {
  if (type === 'filter') {
    return (
      <StyledSkeleton data-testid="filter-skeleton">
        <div className="item">
          <div>
            <div className="wave" />
          </div>
          <div>
            <div className="wave" />
          </div>
        </div>
        <div className="item">
          <div>
            <div className="wave" />
          </div>
          <div>
            <div className="wave" />
          </div>
        </div>
      </StyledSkeleton>
    );
  }

  if (type === 'bar') {
    return (
      <StyledSkeleton>
        <div className="tbody">
          <div className="wave" />
        </div>
      </StyledSkeleton>
    );
  }

  return (
    <StyledSkeleton flex="1" className="table">
      <div className="thead" />
      <div className="tbody">
        <div className="wave" />
      </div>
      <div className="tbody">
        <div className="wave" />
      </div>
    </StyledSkeleton>
  );
};

Skeleton.propTypes = {
  type: PropTypes.string,
};

export default Skeleton;
