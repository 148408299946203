import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';

import { ReactComponent as FilterIcon } from 'assets/images/icons/filter.svg';
import Box from '../Box';
import Button from '../Button';
import Card from '../Card';
import Text from '../Text';
import CardRow from '../CardRow';

const FilterContainer = styled(Box)`
  position: relative;
  width: 100%;
`;

const StyledFilterIcon = styled(FilterIcon)(
  ({ theme }) => css`
    width: 22px;
    margin-right: ${theme.space[2]}px;
  `,
);

const CardMenu = styled(Card)(
  ({ theme }) => css`
    position: absolute;
    z-index: 999;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 360px;
    margin-top: ${theme.space[2]}px;
    cursor: auto;
  `,
);

const FilterButton = ({
  onClear,
  onFilter,
  selectedFilters,
  children,
  childrenStyle,
  ...props
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentFilters, setCurrentFilters] = useState([]);

  const handleClear = () => {
    onClear();
    setIsMenuOpen(false);
    setCurrentFilters([]);
  };

  const handleFilter = () => {
    onFilter();
    setIsMenuOpen(false);
    setCurrentFilters(selectedFilters);
  };

  const containerRef = useRef(null);

  const handleOutsideClick = event => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <FilterContainer
      data-testid="filter-button-container"
      ref={containerRef}
      display="inline-flex"
      flexWrap={['wrap', 'nowrap']}
      alignItems="center"
      {...props}
    >
      <Button
        data-testid="button-filter-assignments"
        variant="text"
        aria-expanded={isMenuOpen}
        aria-haspopup="true"
        onClick={() => setIsMenuOpen(state => !state)}
        px="2"
        pb="0"
        color="#8fb0d4"
        width={'100%'}
        childrenStyle={childrenStyle}
      >
        <StyledFilterIcon />
        <Box color={'#1e3f6b'}>
          <FormattedMessage id="q-system.filter_button.filter_assignments_label" />
        </Box>
      </Button>
      <Text ml="2" fontSize="1">
        {!!currentFilters.length && (
          <>
            <FormattedMessage id="q-system.filter_button.showing" />{' '}
            {currentFilters.filter(Boolean).join(' × ')}
          </>
        )}
      </Text>
      <CardMenu
        role="menu"
        hidden={!isMenuOpen}
        data-testid="card-menu"
        onClick={e => e.stopPropagation()}
      >
        {children}
        <CardRow justifyContent="space-evenly" px="0" pt="4">
          <Button
            data-testid="clear-button"
            variant="text"
            width="50%"
            onClick={handleClear}
          >
            <FormattedMessage id="q-system.filter_button.clear" />
          </Button>
          <Button
            data-testid="filter-button"
            variant="text"
            width="50%"
            color="accent"
            onClick={handleFilter}
          >
            <FormattedMessage id="q-system.filter_button.filter" />
          </Button>
        </CardRow>
      </CardMenu>
    </FilterContainer>
  );
};

FilterButton.propTypes = {
  onClear: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.string.isRequired),
  children: PropTypes.node.isRequired,
  childrenStyle: PropTypes.object,
};

export default FilterButton;
