const theme = {
  fonts: {
    main:
      "'Open Sans', -apple-system, BlinkMacSystemFont, 'Helvetica Neue','Hiragino Sans', Meiryo, 'MS PGothic', sans-serif",
  },
  fontWeights: {
    body: 400,
    bold: 700,
  },
  fontSizes: [12, 14, 16, 20, 22, 28, 34],
  lineHeights: {
    main: 1.5,
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 96],
  breakpoints: ['40em', '57.5em'],
  colors: {
    white: '#fff',
    accent: '#009feb',
    grey: '#e3ecf5',
    greyblue: '#8fb0d4',
    text: '#1e3f6b',
    darkblue: '#2A4873',
    background: '#f6f7fa',
    border: '#bed2e8',
    secondary: '#6b8fbc',
    tertiary: '#a0b8d6',
    danger: '#f9627a',
    warning: '#feb881',
    success: '#35cb72',
    green: '#00cbae',
    blue: '#3481C6',
    prussianBlue: '#0c2846',
    highlight: '#DCF4FE',
  },
};

const {
  fontSizes: { 2: fontSizeBody },
} = theme;
theme.fontSizes.body = fontSizeBody;
theme.breakpoints.minWidth = '320px';

export default theme;
