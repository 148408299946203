import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { ReactComponent as NoResult } from 'assets/images/no-results.svg';
import { Box, Text, Button } from 'q-system';
import { getCurrentLocale } from 'utils/I18n';

const SizedImg = styled.img`
  width: 70%;
  max-width: 335px;
`;

const StyledNoResult = styled(NoResult)`
  width: 180px;
`;

const ErrorUI = ({ error }) => {
  const { formatMessage: f } = useIntl();
  const handledHTTPCodes = [404, 503];
  const errorStatus = error.status || (error.response && error.response.status);

  if (handledHTTPCodes.includes(errorStatus)) {
    // handled HTTP codes are already filtered
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const errorSVG = require(`assets/images/errors/${errorStatus}.svg`);

    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="700px"
        height="100%"
        pt="6"
        mx="auto"
        textAlign="center"
      >
        <SizedImg src={errorSVG} alt={errorStatus} />
        <Text as="h1" fontSize="6" fontWeight="bold" color="darkblue" mt="5">
          {f({ id: `errors.${errorStatus}.title` })}
        </Text>
        <Text fontSize="3" color="darkblue" mt="4">
          {f({ id: `errors.${errorStatus}.subtitle` })}
        </Text>
        <Text fontSize="2" color="darkblue" mt="3">
          {f({ id: `errors.${errorStatus}.info` })}
        </Text>
        <Button
          as="a"
          href={`/${getCurrentLocale()}/dashboard`}
          width="100%"
          maxWidth="380px"
          variant="secondary"
          mt="6"
        >
          {f({ id: 'common.go_back' })}
        </Button>
      </Box>
    );
  }

  if (errorStatus === 403) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        maxWidth="700px"
        height="100%"
        pt="8"
        mx="auto"
        textAlign="center"
      >
        <StyledNoResult />
        <Text as="h1" fontSize="4" my="3" fontWeight="bold" color="darkblue">
          {f({ id: 'errors.not_found.title' })}
        </Text>
        <Box maxWidth="80%">
          <Text as="p" color="darkblue" mb="4">
            {f({ id: 'errors.not_found.info' })}
          </Text>
          <Text as="p" color="darkblue">
            {f({ id: 'errors.not_found.action' })}
          </Text>
        </Box>
        <Button
          as="a"
          href={`/${getCurrentLocale()}/dashboard`}
          width="100%"
          maxWidth="380px"
          mt="6"
        >
          {f({ id: 'common.go_back' })}
        </Button>
      </Box>
    );
  }
  if (errorStatus === 500) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        maxWidth="700px"
        height="100%"
        pt="6"
        mx="auto"
        textAlign="center"
      >
        <Text as="h1" fontSize="6" fontWeight="bold" color="darkblue">
          {f({ id: `errors.${errorStatus}.title` })}
        </Text>
        <Text fontSize="0" color="darkblue" mb="4">
          {f({ id: `errors.${errorStatus}.subtitle` })}
        </Text>
        <Box maxWidth="380px">
          <Text as="p" color="darkblue" mb="4">
            {f({ id: `errors.${errorStatus}.info` })}
          </Text>
          <Text as="p" color="darkblue">
            {f({ id: `errors.${errorStatus}.action` })}
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      pt="6"
      mx="auto"
      textAlign="center"
    >
      <Text as="h1" fontSize="4" fontWeight="bold" color="darkblue">
        {f({ id: 'common.errors.unknown' })}
      </Text>
      <Button
        as="a"
        href={`/${getCurrentLocale()}/dashboard`}
        width="100%"
        maxWidth="380px"
        mt="6"
      >
        {f({ id: 'common.go_back' })}
      </Button>
    </Box>
  );
};

ErrorUI.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
};

export default ErrorUI;
