import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as IconVisible } from 'assets/images/icons/visible.svg';
import { InputWrapper, StyledInput } from './styles';
import { groupStyleProps, stylePropsShape } from '../utils';

const InputToggle = styled.button(
  ({ theme }) => css`
    height: 100%;
    border: none;
    background: none;
    position: relative;
    padding: 0 ${theme.space[2]}px;
    color: currentColor;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &::after {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 2px;
      height: 60%;
      margin: auto;
      border-radius: 1px;
      background-color: currentColor;
      opacity: 1;
      transform: translateX(1%) translateY(-2%) rotate(45deg);
      pointer-events: none;
      content: '';
    }

    &[aria-pressed='true']::after {
      opacity: 0;
    }
  `,
);

const StyledIconVisible = styled(IconVisible)`
  height: 24px;
  fill: currentColor;
  pointer-events: none;
`;

const InputPassword = ({ styleProps, ...props }) => {
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputWrapper isFocused={inputHasFocus} {...styleProps}>
      <StyledInput
        data-testid="input-password"
        type={showPassword ? 'text' : 'password'}
        onFocus={() => setInputHasFocus(true)}
        onBlur={() => setInputHasFocus(false)}
        {...props}
      />
      <InputToggle
        data-testid="toggle"
        type="button"
        aria-pressed={showPassword}
        onClick={() => setShowPassword(!showPassword)}
      >
        <StyledIconVisible tabIndex="-1" />
      </InputToggle>
    </InputWrapper>
  );
};

InputPassword.propTypes = {
  styleProps: stylePropsShape,
};

export default groupStyleProps(InputPassword);
