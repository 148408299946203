import styled, { css } from 'styled-components';
import {
  color,
  display,
  flexbox,
  space,
  typography,
  variant,
} from 'styled-system';

const Text = styled.span(
  ({ theme }) => css`
    color: ${theme.colors.text};
    font: ${theme.fontSizes.body}px ${theme.fonts.main};
    line-height: ${theme.lineHeights.main};
  `,
  variant({
    variants: {
      title: {
        fontSize: '3',
        fontWeight: 700,
      },
      subtitle: {
        color: 'secondary',
        fontSize: '1',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      disabled: {
        color: 'secondary',
      },
      editLink: {
        color: '#009feb',
        fontWeight: 'bold',
        fontSize: '0',
        textDecoration: 'none',
      },
      label: {
        background: '#e3ecf5',
        fontSize: '1',
        fontWeight: 'bold',
        padding: '4px 16px',
        marginRight: '16px',
        borderRadius: '4px',
      },
      inverseLabel: {
        color: '#F3A44C',
        fontSize: '1',
        fontWeight: 'bold',
        borderRadius: '4px',
        textTransform: 'uppercase',
      },
      notice: {
        color: 'white',
        fontSize: '1',
      },
    },
  }),
  display,
  color,
  flexbox,
  space,
  typography,
  ({ textDecoration }) =>
    textDecoration &&
    css`
      text-decoration: ${textDecoration};
    `,
  ({ textTransform }) =>
    textTransform &&
    css`
      text-transform: ${textTransform};
    `,
  ({ wordBreak }) =>
    wordBreak &&
    css`
      word-break: ${wordBreak};
    `,
);

export default Text;
