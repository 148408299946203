import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { InputWrapper } from './styles';
import { groupStyleProps, stylePropsShape } from '../utils';

const TextareaWrapper = styled(InputWrapper)`
  height: 88px;
`;

const StyledTextarea = styled.textarea(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    padding: 12px ${theme.space[3]}px;
    border: none;
    background: none;
    color: ${theme.colors.text};
    font: ${theme.fontSizes[1]}px ${theme.fonts.main};
    resize: none;
    cursor: text;

    /* Firefox shows red outline on required textareas by default */
    &:required {
      box-shadow: none;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #8fb0d4;
    }

    &:disabled {
      color: ${theme.colors.tertiary};
    }
  `,
);

const Textarea = ({ styleProps, ...props }) => {
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const { disabled } = props;

  return (
    <TextareaWrapper
      isFocused={inputHasFocus}
      isDisabled={disabled}
      {...styleProps}
    >
      <StyledTextarea
        data-testid="textarea"
        onFocus={() => setInputHasFocus(true)}
        onBlur={() => setInputHasFocus(false)}
        {...props}
      />
    </TextareaWrapper>
  );
};

Textarea.propTypes = {
  styleProps: stylePropsShape,
  disabled: PropTypes.bool,
};

export default groupStyleProps(Textarea);
