import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import styled, { css } from 'styled-components';

import { Box, Card, CardRow, Text } from 'q-system';
import qTheme from 'q-system/theme';
import { ModalContext } from 'utils/Modal';
import { ReactComponent as IconX } from 'assets/images/icons/x.svg';

const ModalCard = styled(Card)`
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  outline: none;
  max-height: 85vh;
`;

const StyledCloseIcon = styled(IconX)(
  ({ theme }) => css`
    width: ${theme.space[3]}px;
    height: ${theme.space[3]}px;
    cursor: pointer;
    fill: ${theme.colors.text};
  `,
);

const titleProps = {
  height: '56px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: '1px solid #e9eeef',
};

const Modal = ({
  title,
  children,
  mt = '25vh',
  withCloseIcon = false,
  ...props
}) => {
  const { isOpen, hideModal } = useContext(ModalContext);

  return (
    <ModalCard
      as={ReactModal}
      isOpen={isOpen}
      onRequestClose={hideModal}
      shouldCloseOnOverlayClick={false}
      maxWidth="568px"
      marginX="auto"
      mt={mt}
      style={{
        overlay: {
          zIndex: '1000',
          padding: qTheme.space[3],
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <CardRow {...titleProps}>
        <Text fontWeight="bold">{title}</Text>
        {withCloseIcon && (
          <StyledCloseIcon
            onClick={() => hideModal()}
            data-testid="close-btn"
          />
        )}
      </CardRow>
      <Box {...props}>{children}</Box>
    </ModalCard>
  );
};

Modal.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  mt: PropTypes.string,
  withCloseIcon: PropTypes.bool,
};

export default Modal;
