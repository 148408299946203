import React from 'react';
import styled, { css } from 'styled-components';

const StyledThead = styled.thead(
  ({ theme }) => css`
    background-color: ${theme.colors.grey};
    text-align: left;

    tr.disabled {
      background: unset;
    }
  `,
);

const Thead = props => <StyledThead {...props} />;

export default Thead;
