import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import styled from 'styled-components';

import { ReactComponent as EmptyImage } from 'assets/images/no-assignment.svg';
import Box from '../Box';
import Card from '../Card';
import Text from '../Text';
import Skeleton from '../Skeleton';

export const TableHeadersContext = React.createContext({});

const StyledEmptyImage = styled(EmptyImage)`
  width: 180px;
`;

const Table = ({
  isFetching = false,
  isEmpty,
  emptyText,
  children,
  mobile,
  ...props
}) => {
  // Use useRef because it won't trigger a re-render
  // when its value is changed
  const headers = useRef([]);

  if (isFetching) {
    return mobile ? (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    ) : (
      <>
        <Skeleton />
      </>
    );
  }

  return (
    <TableHeadersContext.Provider
      value={{
        headers,
      }}
    >
      <Card as="table" {...props}>
        {isEmpty ? (
          <tbody>
            <tr>
              <td>
                <Box
                  display="flex"
                  height="477px"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <StyledEmptyImage />
                  <Text fontWeight="bold" mt="6">
                    {emptyText}
                  </Text>
                </Box>
              </td>
            </tr>
          </tbody>
        ) : (
          children
        )}
      </Card>
    </TableHeadersContext.Provider>
  );
};

Table.propTypes = {
  isEmpty: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  emptyText: PropTypes.node,
  children: PropTypes.node,
  mobile: PropTypes.bool,
};

export default Table;
