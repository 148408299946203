import cookie from 'cookie_js';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/browser';

import AppFooter from 'app/AppFooter';
import AppHeader from 'app/AppHeader';
import ErrorUI from 'common/ErrorUI';
import { UserProvider } from 'context/UserContext';
import { EssayProvider } from 'context/EssayContext';
import { FormProvider } from 'context/FormContext';
import { ThemeContextProvider } from 'context/ThemeContext';
import { Box, Wrapper } from 'q-system';
import theme from 'q-system/theme';
import { DEFAULT_LOCALE, I18nProvider } from 'utils/I18n';
import { ModalProvider } from 'utils/Modal';
import Loader from 'common/Loader';

const RelativeBox = styled(Box)`
  position: relative;
`;

class AppShell extends React.Component {
  constructor(props) {
    super(props);
    this.state = { i18n: undefined, user: undefined, error: '' };

    this.handleI18nLoaded = this.handleI18nLoaded.bind(this);
    this.handleUserLoaded = this.handleUserLoaded.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  /* eslint-disable class-methods-use-this */
  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }
  /* eslint-enable class-methods-use-this */

  handleI18nLoaded(data) {
    this.setState({ i18n: data });
  }

  handleUserLoaded(data) {
    this.setState({ user: data });
  }

  renderChildren() {
    if (this.state.error) {
      return <ErrorUI error={this.state.error} />;
    }

    if (!this.state.i18n || !this.state.user) {
      return <Loader />;
    }

    return this.props.children({
      user: this.state.user,
    });
  }

  render() {
    const locale =
      window.location.pathname.split('/')[1] ||
      cookie.get('language') ||
      DEFAULT_LOCALE;

    return (
      <I18nProvider locale={locale} onLoad={this.handleI18nLoaded}>
        <ThemeProvider theme={theme}>
          <ThemeContextProvider>
            <ModalProvider>
              <UserProvider onLoad={this.handleUserLoaded}>
                <EssayProvider>
                  <FormProvider>
                    <Wrapper
                      display="flex"
                      flexDirection="column"
                      minWidth={theme.breakpoints.minWidth}
                      height="100vh"
                    >
                      <AppHeader hasError={!!this.state.error} />
                      <RelativeBox
                        flex="1 0 auto"
                        width="100%"
                        maxWidth="1200px"
                        mx="auto"
                      >
                        {this.renderChildren()}
                      </RelativeBox>
                      <AppFooter flexShrink="0" />
                    </Wrapper>
                  </FormProvider>
                </EssayProvider>
              </UserProvider>
            </ModalProvider>
          </ThemeContextProvider>
        </ThemeProvider>
      </I18nProvider>
    );
  }
}

export default AppShell;

AppShell.propTypes = {
  children: PropTypes.func.isRequired,
};
