import { createGlobalStyle, css } from 'styled-components';

// Disable rule because this is a utilities file
// and is expected to have many exports
// eslint-disable-next-line import/prefer-default-export
export const Background = createGlobalStyle(
  ({ theme, color }) => css`
    body {
      background: ${theme.colors[color] || color};
    }
  `,
);
