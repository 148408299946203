import React from 'react';
import cookie from 'cookie_js';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Select from 'react-select';
import styled, { css } from 'styled-components';
import theme from 'q-system/theme';
import { getCurrentLocale } from 'utils/I18n';

const menus = [
  { value: 'en', label: 'English' },
  { value: 'id', label: 'Bahasa Indonesia' },
];

const StyledSwitcher = styled(Select)(
  () => css`
    width: 160px;
    margin-right: ${theme.space[3]}px;
    cursor: pointer;
    font: ${theme.fontSizes[1]}px ${theme.fonts.main};

    .switcher__option:active {
      background: #d6f5ff;
    }
  `,
);

const StyledSwitcherMobile = styled(Select)(
  () => css`
    width: 90px;
    margin-right: ${theme.space[3]}px;
    cursor: pointer;
    font: ${theme.fontSizes[1]}px ${theme.fonts.main};

    .switcher__option:active {
      background: #d6f5ff;
    }
  `,
);

const baseStyle = {
  menu: provided => ({
    ...provided,
    width: '200px',
    border: 'none',
    boxShadow: '0 6px 8px 2px rgba(0,0,0,.1)',
    padding: `${theme.space[2]}px 0`,
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
    padding: `${theme.space[3]}px`,
    background: state.isFocused ? '#d6f5ff' : 'white',
    color: `${theme.colors.text}`,
  }),
  control: () => ({
    display: 'flex',
    background: 'transparent',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'white',
  }),
  indicatorSeparator: () => ({
    visibility: 'hidden',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: '8px 4px',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
};

export const customStyle = {
  ...baseStyle,
  valueContainer: provided => ({
    ...provided,
    justifyContent: 'flex-end',
    paddingRight: 0,
  }),
  singleValue: provided => ({
    ...provided,
    color: `${theme.colors.secondary}`,
  }),
};

export const customStyleMobile = {
  ...baseStyle,
  valueContainer: provided => ({
    ...provided,
    paddingRight: 0,
    paddingLeft: 0,
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
  }),
};

const LocaleSwitcher = ({ mobile }) => {
  const history = useHistory();
  const menuIndex = menus.findIndex(o => o.value === getCurrentLocale());

  const switchLocale = val => {
    const locale = val.value;
    let { pathname } = window.location;
    // eslint-disable-next-line
    const re = new RegExp(`^\/${locale}`);
    if (!pathname.match(re)) {
      // eslint-disable-next-line

      pathname = pathname.replace(/^\/[a-z]+/i, `/${locale}`);

      cookie.set('language', locale);
      const newLocation = { ...window.location, pathname };
      history.push(newLocation);
    }
  };

  const Switcher = mobile ? StyledSwitcherMobile : StyledSwitcher;

  return (
    <Switcher
      styles={mobile ? customStyleMobile : customStyle}
      options={menus}
      id="locales"
      menuPlacement="top"
      onChange={switchLocale}
      classNamePrefix="switcher"
      value={menus[menuIndex]}
      menuPortalTarget={document.body}
    />
  );
};

export default LocaleSwitcher;

LocaleSwitcher.propTypes = {
  mobile: PropTypes.bool,
};
