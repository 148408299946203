import PropTypes from 'prop-types';
import React, { createContext, useState, useCallback, useEffect } from 'react';
import ReactModal from 'react-modal';

export const ModalContext = createContext({
  isOpen: false,
  openModal: () => null,
  hideModal: () => null,
});

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState(null);

  useEffect(() => {
    ReactModal.setAppElement('#root');
  }, []);

  const openModal = useCallback(modalToOpen => {
    setModal(modalToOpen);
  }, []);

  const hideModal = useCallback(() => {
    setModal(null);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        isOpen: !!modal,
        openModal,
        hideModal,
      }}
    >
      {modal}
      {children}
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
