import React, { createContext, useState } from 'react';

export const FormContext = createContext({});

export const FormProvider = props => {
  const [isFormDirty, setIsFormDirty] = useState(false);

  return (
    <FormContext.Provider value={{ isFormDirty, setIsFormDirty }} {...props} />
  );
};

export const useForm = () => {
  return React.useContext(FormContext);
};
