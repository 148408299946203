import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { TableHeadersContext } from './Table';

const StyledTh = styled.th(
  ({ theme }) => css`
    height: 60px;
    vertical-align: middle;
    padding-left: ${theme.space[4]}px;

    &:last-child {
      padding-right: ${theme.space[4]}px;
    }

    &:not(:first-child) {
      display: none;

      @media screen and (min-width: ${theme.breakpoints[1]}) {
        display: table-cell;
      }
    }
  `,
);

const Th = props => {
  const { headers } = useContext(TableHeadersContext);

  if (props.children) {
    headers.current = [...headers.current, props.children];
  } else {
    headers.current = [...headers.current, null];
  }

  return <StyledTh {...props} />;
};

Th.propTypes = {
  children: PropTypes.node,
};

export default Th;
