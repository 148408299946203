import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as IconCaret } from 'assets/images/icons/forward.svg';
import { TableHeadersContext } from './Table';

const StyledTd = styled.td(
  ({ theme }) => css`
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    min-height: 64px;
    margin-right: ${theme.space[4]}px;
    margin-left: ${theme.space[4]}px;

    &:not(:first-child) {
      border-top: 1px solid ${theme.colors.grey};
    }

    @media screen and (min-width: ${theme.breakpoints[1]}) {
      display: table-cell;
      height: 64px;
      vertical-align: middle;
      padding-left: ${theme.space[4]}px;

      &:last-child {
        padding-right: ${theme.space[4]}px;
      }

      &:not(:first-child) {
        border-top: none;
      }
    }
  `,
);

const StyledIconCaret = styled(IconCaret)(
  ({ theme }) => css`
    position: absolute;
    right: 0;
    width: 16px;
    margin-left: ${theme.space[3]}px;
    fill: ${theme.colors.text};

    @media screen and (min-width: ${theme.breakpoints[1]}) {
      display: none;
    }

    &[aria-expanded='true'] {
      transform: rotate(90deg);
    }
  `,
);

const TdHeaderContainer = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints[1]}) {
      display: none;
    }
  `,
);

const StyledTableCaret = styled(IconCaret)(
  ({ theme }) => css`
    position: absolute;
    right: 0;
    width: 16px;
    margin-left: ${theme.space[3]}px;
    margin-right: ${theme.space[3]}px;
    fill: ${theme.colors.greyblue};
  `,
);

const Td = ({
  columnIndex,
  isOpen,
  children,
  lastColumn = false,
  ...props
}) => {
  const { headers } = useContext(TableHeadersContext);
  const tdHeader = headers.current[columnIndex];

  if (!tdHeader) {
    return <StyledTd {...props}>{children}</StyledTd>;
  }

  if (columnIndex === 0) {
    return (
      <StyledTd {...props}>
        {children}
        <StyledIconCaret aria-expanded={isOpen} />
      </StyledTd>
    );
  }

  return (
    <StyledTd {...props} display="flex" alignItems="center">
      <TdHeaderContainer className="label">
        {React.cloneElement(tdHeader)}
      </TdHeaderContainer>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {children}
        {lastColumn && <StyledTableCaret className="chevron" />}
      </div>
    </StyledTd>
  );
};

Td.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  lastColumn: PropTypes.bool,
};

export default Td;
