import React from 'react';
import styled, { css } from 'styled-components';

const StyledRadio = styled.input(
  () => css`
    width: 12px;
    height: 12px;
  `,
);

const Radio = ({ ...props }) => {
  return <StyledRadio type="radio" {...props} />;
};

export default Radio;
