import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { ReactComponent as Tick } from 'assets/images/icons/tick.svg';
import { groupStyleProps, stylePropsShape } from '../utils';
import Box from '../Box';

export const StyledCheckbox = styled.input(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    position: absolute;
    opacity: 0;

    &:checked + svg {
      background-color: ${theme.colors.accent};
    }

    &:checked[disabled] + svg {
      background-color: ${theme.colors.grey};
      border: none;

      g#tick_path {
        fill: ${theme.colors.tertiary};
      }
    }
  `,
);

const StyledTick = styled(Tick)(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid ${theme.colors.border};
  `,
);

const InputText = ({ styleProps, ...props }) => {
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const { disabled } = props;
  const { width } = styleProps;

  return (
    <Box width={width} mr="2" data-testid="input-checkbox-container">
      <StyledCheckbox
        data-testid="input-checkbox"
        type="checkbox"
        isDisabled={disabled}
        data-focus={inputHasFocus}
        onFocus={() => setInputHasFocus(true)}
        onBlur={() => setInputHasFocus(false)}
        {...props}
      />
      <StyledTick />
    </Box>
  );
};

InputText.propTypes = {
  styleProps: stylePropsShape,
  disabled: PropTypes.bool,
};

export default groupStyleProps(InputText);
