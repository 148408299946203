import flatten from 'flat';
import cookie from 'cookie_js';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';
import mergeWith from 'lodash/mergeWith';
import '@formatjs/intl-relativetimeformat/polyfill';

import baseTranslation from 'translations/en-x-base.json';

const QUIPPER_LOCALES = ['en', 'id'];
const SUPPORTED_LOCALES = ['en', 'id'];
export const DEFAULT_LOCALE = 'en';

export const getUrlLocaleMatch = (path = window.location.pathname) => {
  const localeRegex = QUIPPER_LOCALES.join('|');
  const pathLocaleMatch = path.match(`^/(${localeRegex})(/.*)*$`);

  return pathLocaleMatch;
};

export const getCurrentLocale = () => {
  const urlLocale = getUrlLocaleMatch();

  if (urlLocale) {
    return urlLocale[1];
  }

  const cookieLocale = cookie.get('language');

  if (SUPPORTED_LOCALES.includes(cookieLocale)) {
    return cookieLocale;
  }

  return DEFAULT_LOCALE;
};

export const isCurrentLocaleValid = () => {
  return SUPPORTED_LOCALES.includes(getCurrentLocale());
};

export const localizePath = (path = '') => {
  return `/${getCurrentLocale()}${path}`;
};

export const I18nProvider = ({ locale: requestedLocale, onLoad, children }) => {
  const [i18n, setI18n] = useState({
    currentLocale: DEFAULT_LOCALE,
    messages: flatten(baseTranslation),
  });

  const loadTranslation = async localeToLoad => {
    const translationModule = await import(`translations/${localeToLoad}.json`);
    const currentTranslation = translationModule.default;
    const mergedTranslations = mergeWith(
      flatten(baseTranslation),
      flatten(currentTranslation),
      (baseValue, currentValue) => currentValue || baseValue,
    );

    return mergedTranslations;
  };

  useEffect(() => {
    if (SUPPORTED_LOCALES.includes(requestedLocale)) {
      loadTranslation(requestedLocale).then(messages => {
        setI18n({ currentLocale: requestedLocale, messages });
        onLoad(requestedLocale);
      });
    } else {
      onLoad(DEFAULT_LOCALE);
    }
  }, [requestedLocale, onLoad]);

  return (
    <IntlProvider
      locale={i18n.currentLocale}
      key={i18n.currentLocale}
      messages={i18n.messages}
    >
      {children}
    </IntlProvider>
  );
};

I18nProvider.propTypes = {
  locale: PropTypes.string,
  onLoad: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
