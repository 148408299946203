import flow from 'lodash/flow';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import InputText from './InputText';

const StyledInputNumber = styled(InputText)`
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button,
  &[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    text-align: right;
    margin: 0;
  }
`;

const withNewValue = (event, value) => {
  return {
    ...event,
    target: {
      ...event.target,
      value: Number.isNaN(value) ? '' : value,
    },
  };
};

const ALLOWED_KEYCODES = [37, 38, 39, 40, 8, 9, 16]; // Arrow buttons, tab, backspace, and shift

const InputNumber = ({ onChange, ...props }) => {
  const handleKeyDown = event => {
    // Allow numerical input and specified keycodes
    if (
      !(event.keyCode >= 48 && event.keyCode <= 57) &&
      !(event.keyCode >= 96 && event.keyCode <= 105) &&
      !ALLOWED_KEYCODES.includes(event.keyCode)
    ) {
      event.preventDefault();
    }
  };

  const makeInteger = event => {
    if (Number.isInteger(parseFloat(props.step))) {
      return withNewValue(event, parseInt(event.target.value, 10));
    }
    return event;
  };

  const makeIntoMaxScore = event => {
    if (event.target.value > props.max) {
      return withNewValue(event, props.max);
    }
    return event;
  };

  const makePositive = event => {
    if (parseFloat(props.min) >= 0) {
      return withNewValue(event, Math.abs(event.target.value));
    }
    return event;
  };

  const makeString = event => {
    if (typeof event.target.value === 'number') {
      return withNewValue(event, event.target.value.toString());
    }
    return event;
  };

  const transformValue = flow(
    makeInteger,
    makeIntoMaxScore,
    makePositive,
    makeString,
  );

  const handleChange = event => {
    if (event.target.value === '') {
      return onChange(event);
    }

    return onChange(transformValue(event));
  };

  return (
    <StyledInputNumber
      type="number"
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      {...props}
    />
  );
};

InputNumber.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  step: PropTypes.string,
  onChange: PropTypes.func,
};

export default InputNumber;
