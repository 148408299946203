import React, { useEffect, useState } from 'react';
import { BoxWide, Text } from 'q-system';
import theme from 'q-system/theme';
import PropTypes from 'prop-types';

const Flash = ({ delta, message, callback = null, type = 'info' }) => {
  const FLASH_HEIGHT = '40px';
  const [visible, setVisibility] = useState(true);
  const [height, setHeight] = useState(FLASH_HEIGHT);
  const bgColor = {
    info: theme.colors.green,
    warn: theme.colors.warning,
    alert: theme.colors.danger,
  };

  useEffect(() => {
    setVisibility(true);
    setHeight(FLASH_HEIGHT);
    const handleTimeout = () => {
      setHeight('0px');

      // TODO: this component needs to be unmounted from the DOM
      // onTimeout. otherwise it will not load with a visibility of false
      // it's not an issue if the browser reloads every time
      // but the point of spa is to avoid having to do a page reload everytime
      setTimeout(() => {
        setVisibility(false);
        if (callback) {
          callback();
        }
      }, 1000);
    };
    const startTime = () => {
      return setTimeout(handleTimeout, 5000);
    };

    const timeoutId = startTime();
    window.scrollTo(0, 0);

    return () => clearTimeout(timeoutId);
  }, [delta]);

  return (
    <>
      {visible && (
        <BoxWide
          data-testid="flash"
          bg={bgColor[type]}
          color="white"
          justifyContent="center"
          py={0}
          transition="height 1s"
          height={height}
          position="fixed"
          top="74px"
          left="0"
          zIndex="100"
        >
          <Text fontSize="1" color="white" py="2">
            {message}
          </Text>
        </BoxWide>
      )}
    </>
  );
};

Flash.propTypes = {
  delta: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  callback: PropTypes.func,
};

export default Flash;
