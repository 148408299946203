import PropTypes from 'prop-types';
import React from 'react';

import { Box } from 'q-system';

const Avatar = ({ src, ...props }) => {
  return (
    <Box
      data-testid="avatar"
      background={`url(${src}) no-repeat`}
      backgroundSize="cover"
      width="40px"
      height="40px"
      border="1px solid #bed2e8"
      borderRadius="999em"
      {...props}
    />
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
};

export default Avatar;
